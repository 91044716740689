import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/72cefc1d/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import Fam from "./images/fam";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <p>{`Dr. Grant Rowland is a native of Dallas and Texas A&M Alum who has returned to College Station to join the specialists at Central Texas Sports Medicine and Orthopedics. His medical training began in Galveston, Texas, where he graduated with a Doctorate of Medicine in 2011.`}</p>
    </PageDescription>
    <Row mdxType="Row">
      <Column colMd={4} colLg={6} mdxType="Column">
        <p>{`He then completed his orthopedic surgical residency in Kansas City at the University of Kansas Medical Center where he was peer-elected as Chief Resident in 2010. During this time, Dr. Rowland assisted in care for multiple local high-school teams and published research covering ultrasound and surgical evaluation of the knee in recognized peer-reviewed journals.`}</p>
        <p>{`Dr. Rowland was then selected for an ACGME-accredited Fellowship in Orthopedic Sports Medicine at the University of Kentucky in Lexington. While in Kentucky, he received training from international specialists on advanced arthroscopic and open surgical techniques of the knee and shoulder. In addition, he was actively involved in care for the University of Kentucky athletic program, the Kentucky State University athletic program, and several Lexington high schools.`}</p>
        <p>{`As an active member of the American Orthopaedic Society for Sports Medicine, the American Orthopedic Association, American Orthopedic Association, and Texas Medical Association, Dr. Rowland remains on the forefront of education and technology for orthopedics and sports medicine. He has given multiple presentations at national meetings and published original research in peer-reviewed journals.`}</p>
      </Column>
      <Column colMd={4} colLg={6} mdxType="Column">
        <Fam mdxType="Fam" />
        <Caption mdxType="Caption">
  Dr. Rowland and his wife, Sally, are excited to join the Bryan-College Station
  community where they will raise their daughters, Heidi and Halle.
        </Caption>
      </Column>
    </Row>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      